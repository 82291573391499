<template>
	<div class="home">
		
		<CookieBanner/>
		<HomePage/>
		<TaigaPage/>
		<FountainsPage :render_lang="$root.$i18n.locale"/>
		<AboutUsPage/>
		<ContactPage/>
		
	</div>
</template>

<script>
// @ is an alias to /src
import CookieBanner from '@/components/common/CookieBanner.vue'

import HomePage from "@/pages/HomePage"
import TaigaPage from "@/pages/TaigaPage"
import FountainsPage from "@/pages/FountainsPage"
import AboutUsPage from "@/pages/AboutUsPage"
import ContactPage from "@/pages/ContactPage"


export default { 
	name: 'Main',
	components: {
		CookieBanner
	},
	data: function(){
		return  {
			"render_lang":0
		}
	},
	pages:{
		HomePage,
		TaigaPage,
		FountainsPage,
		AboutUsPage,
		ContactPage
	}
}
</script>
