<template>

	<div  v-if="display_cookies" class="cookie-banner">

		<div class="banner-content">
			
			<div v-if="managing" class="manage">

				<h4 v-html="$t('cookies.manage.title')"></h4>
				<p v-html="$t('cookies.manage.description')"></p>
				
				<div class="buttons"> 
					<button v-on:click="accept">{{$t('cookies.manage.enable_all')}}</button>
				</div>

				<table>
					<tr>
						<td class="left">
							<a href="#" v-if="!cookie_type.strictly_necessary.show_description" v-on:click="showDescription('strictly_necessary')">+</a>
							<a href="#" v-else v-on:click="hideDescription('strictly_necessary')">-</a>

							{{$t('cookies.manage.cookie_type.strictly_necessary.title')}}

							<p class="cookie_type_description" v-if="cookie_type.strictly_necessary.show_description" v-html="$t('cookies.manage.cookie_type.strictly_necessary.description')"></p>

						</td>
						<td class="right">
							<p class="cookie_type_description" v-html="$t('cookies.manage.always_active')"></p>							
						</td>
					</tr>
					<tr>
						<td class="left">
							<a href="#" v-if="!cookie_type.performance.show_description" v-on:click="showDescription('performance')">+</a>
							<a href="#" v-else v-on:click="hideDescription('performance')">-</a>

							{{$t('cookies.manage.cookie_type.performance.title')}}

							<p class="cookie_type_description" v-if="cookie_type.performance.show_description" v-html="$t('cookies.manage.cookie_type.performance.description')"></p>

						</td>
						<td class="right">
							<input type="checkbox" v-model="cookie_type.performance.enabled">
						</td>
					</tr>

				</table>

				

			</div>
			<div v-else class="initial-text">
				<p v-html="$t('cookies.text',{'url':currentLocale+'/cookies'})"></p>	
			</div>
			
		</div>	
		<div class="buttons bottom"> 
			<template v-if="managing">
				<button v-on:click="reject">{{$t('cookies.manage.disable_all')}}</button>	
				<button v-on:click="custom">{{$t('cookies.manage.confirm_preferences')}}</button>
			</template>
			<template v-else>
				<button class="manage" v-on:click="manage">{{$t('cookies.manage_button')}}</button>
				<button v-on:click="reject">{{$t('cookies.manage.disable_all')}}</button>
				<button v-on:click="accept">{{$t('cookies.manage.enable_all')}}</button>
			</template>
			
			
		</div>
	</div>

  
  
</template>

<script>

import { Trans } from '@/plugins/Translation'

export default 
{ 

	components:{
		
	},
	
	name: 'CookieBanner',

	computed: {
      currentLocale () {
        return Trans.currentLocale
      }
    },

	data()
	{
		return {
			paused: false,
			display_cookies: true,
			managing: false,
			cookie_type:
			{
				strictly_necessary:
				{
					enabled:true,
					show_description:false
				},
				performance:
				{
					enabled:true,
					show_description:false
				},
				targeted:
				{
					enabled:true,
					show_description:false
				}
			}
		}
	},

	mounted:function(){

        if(localStorage.getItem('display_cookies') != null)
        {
            this.display_cookies = false;
        }
        this.setup();
    },

	methods:{

		setup()
        {
            if(this.getCookie('cookies_acceptance'))
            {
                this.$gtm.enable(true);

                window.dataLayer?.push({
                    event: "gtm.init_consent"
                });
            }
        },

		accept()
		{
			localStorage.setItem('display_cookies', '0');
			localStorage.setItem('cookies_acceptance', '1');
			this.setCookie('cookies_acceptance', true, 7);
			this.display_cookies = false;

			this.setup();

		},
		manage()
		{
			this.managing = true;
		},
		reject()
		{
			localStorage.setItem('display_cookies', '0');
			localStorage.setItem('cookies_acceptance', '0');
			this.setCookie('cookies_acceptance', 0, 7);
			this.display_cookies = false;

			this.setup();

		},	
		custom()
		{
			localStorage.setItem('display_cookies', '0');
			localStorage.setItem('cookies_acceptance', this.cookie_type.performance.enabled);
			this.setCookie('cookies_acceptance', this.cookie_type.performance.enabled, 7);
			this.display_cookies = false;

			this.setup();

		},

		showDescription(index)
		{
			this.cookie_type[index].show_description = true;
		},

		hideDescription(index)
		{
			this.cookie_type[index].show_description = false;
		},

		//define a function to set cookies
		setCookie(name,value,days)
		{
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				expires = "; expires=" + date.toUTCString();
			}

			document.cookie = name + "=" + (value || "")  + expires + "; path=/";
		},

        getCookie(name)
        {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
        }
	}	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	
	.cookie-banner
	{
		width:450px;
		max-width: calc(100% - 40px);
		height: auto;
		position: fixed;
		background-color: $primary-color;
		bottom: 20px;
		border-radius: 10px;
		left: 20px;
		padding:0;
		z-index: 999999;

		h4{
		
			color:white;
			margin:0;
			padding:0;
			font-weight: bold;
			font-size: 18px;
			line-height: 18px;
			margin-bottom: 20px;
			
		}

		p, td, a{
		
			color:white;
			margin:0;
			padding:0;
			font-size: 16px;
			line-height: 18px;
			text-decoration: none;

			a{
				color: white;
				
			}

			&.cookie_type_description
			{
				font-size: 14px;
			}



		}

		table
		{
			width: 100%;
		}
		td
		{
			&.left
			{
				text-align: left;
			}

			&.right
			{
				text-align: center;

				input
				{
					border: 1px solid white;
					padding: 10px;
				}
			}
		}

		.banner-content
		{
			margin: 0 auto;
			display: block;
			position: relative;
			width:calc(100% - 40px);
			height: auto;
			text-align: center;

			padding: 20px;

			table{
				margin-top: 20px;
			}
			
		}

		.buttons	
		{	
			margin:15px 0 15px 0;	

			&.bottom
			{
				margin:50px 0 15px 0;	
			}

			button
			{

				border: 1px solid white;
				color:$primary-color;
				border-radius: 50px;
				color: white;
				background-color: rgba(255,255,255,0);
				padding: 5px 10px;
				cursor: pointer;
				text-transform: uppercase;
				margin:0 3px;
				
				@include respond-below(md)
				{
					float:left;
					width: calc(100% - 30px);
					margin: 5px 15px;

					&:last-child
					{
						margin-bottom: 20px;
					}
				}

				&.manage
				{
					border:none;
					text-decoration: underline;
				}

			}	
		}

	}

</style>
